.shopping-cart-container {
	display: flex;
	justify-content: space-around;
	gap: .5rem;
	flex-direction: row;
	margin: 0 auto;
	background: rgb(255, 250, 250);
	border-radius: 5px;
	width: 300px;
	height: 100%;


	.column-1 {
		display: block;
			flex:50%;
			width: 100%;
			margin: auto;
			justify-content: center;
			img {
				width: 300px;
				margin: auto;
			}
			}

	&__title {
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
	}
	&__cat {
		font-size: 24px;
		font-weight: bold;
	}

	&__item {
		display: flex;
		order: -2;
		padding: 30px 0 0 30px;


		

		
			.ComponentShoppingCart {
				margin-top: 10px;
			}

		img {
			width: 100px;
		}

		h1,
		p {
			padding: 2px 0;
			margin: 0 20px;
		}

		p {
			font-weight: bold;
		}

		button {
			border-radius: 5px;
			border: 1px solid lightcoral;
			padding: 10px;
			background: transparent;
			transition: 0.15s ease-in-out;
			margin: 10px 0;
			color: lightcoral;
			margin-left: 22px;

			&:hover {
				cursor: pointer;
				background: lightcoral;
				color: white;
			}
		}
	}

	&__checkout {
		margin: 30px 0;
		border-top: 1px solid lightgray;
		padding: 20px 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #141618;
		color: white;

		button {
			border: 1px solid white;
			background: transparent;
			border-radius: 5px;
			padding: 10px;
			color: white;

			&:hover {
				background: black;
				color: white;
			}
		}
	}
	.Block {
		display: flex;
		max-width: fit-content;
		flex-direction: row;
		.column-2 {
			flex-wrap: wrap;
		}
	}
}
