$color_1: #dc143c;
$font-family_1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family_2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family_3: Arial, Helvetica, sans-serif;

/* .with-icon {
  position: relative;
  top: 7px;
  margin-left: 3px;
  margin-right: 1px;
}
.icon-text {
  color: rgba(0, 0, 0, 0);
  font-size: 8px;
  visibility: hidden;
}
.btn-text {
  white-space: nowrap;
  position: relative;
  top: 3px;
  margin: 5px;
  font-size: 18px;
} */
body {
	margin: 0;
	font-family: $font-family_1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: $font-family_2;
}
.table-data {
	font-size: 22px;
	padding: 5px;
}

#price + .table-data-price {
	font-size: 22px;
	width: 50px;
}

.page-bar {
	width: 100%;
	display: flex;
}
.page-list {
	margin: 0 auto;
	position: relative;
	left: -5%;
	display: flex;
	list-style: none;
}
.page-btn {
	font-family: $font-family_3;
	margin: 3px;
	padding-left: 9px;
	padding-right: 9px;
	border-radius: 6px;
	font-weight: bolder;
}
.btn-row {
	display: flex;
}
.btn-middle {
	width: 70%;
}
.container {
	position: relative;
}
.create {
	position: relative;
	margin: 10px;
	font-weight: 800;
	font-family: $font-family_3;
}
.btn {
	display: flex;
	white-space: nowrap;
}
.alert-text {
	color: $color_1;
}
.btn-active {
	background: cyan;
}
.btn-disactive {
	background: cyan;
}
.loading {
	margin: 0px auto;
	position: relative;
	width: 150px;
}
.fas {
	position: relative;
	top: 5px;
	margin-right: 5px;
}
.sort {
	position: relative;
	top: 0;
	margin-right: 0;
}
