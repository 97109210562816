.Category {
  // display: flex;
  // flex-direction: row;
   justify-content: center;
  // gap: 1rem;

  figure {
    display: flex;
    flex-direction: column;
     height: 25vh;
    //  max-width: 25vw;
    //  gap: 2rem;

    figcaption {
      display: flex;
      justify-content: center;
      font-size: 21px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: #333232;
    
    &:hover {
      background-color: rgba(245, 243, 243, 0.664);
      border-radius: 5%;
      figcaption {
        color: #1976d2;
      }}
    }
  }
}