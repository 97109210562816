$color_1: #212121;
$color_2: #fff;
$color_3: white;
$color_4: lightgray;
$color_5: #43a047;
$font-family_1: "Open Sans", sans-serif;
$background-color_1: #43a047;
$background-color_2: cornflowerblue;

// @import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,700";
.ChatApp {
	width: 16, 5vw;
	background: #FFDEA7;
	margin: 0 3rem 2rem 0;
	padding: 0 0.5em 0.5em 0.5em;
	border-radius: 0.5em;
	color: $color_1;
	font-family: $font-family_1;
	display: flex;
	align-items: flex-end;
	position: fixed;
	z-index: 999;
	bottom: 0;
	right: 0.1em;
}
@media (min-width:360px)  { 
	/* smartphones, iPhone, portrait 480x320 phones */
	.ChatApp {
		width: 16, 5vw;
		background: #FFDEA7;
		margin: 0 3rem 2rem 0;
		padding: 0 0.5em 0.5em 0.5em;
		border-radius: 0.5em;
		color: $color_1;
		font-family: $font-family_1;
		display: flex;
		align-items: flex-end;
		position: fixed;
		z-index: 999;
		bottom: 0;
	}
 }
@media (min-width:481px)  {
	 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
	 .ChatApp {
		width: 16, 5vw;
		background: #FFDEA7;
		margin: 0 3rem 2rem 0;
		padding: 0 0.5em 0.5em 0.5em;
		border-radius: 0.5em;
		color: $color_1;
		font-family: $font-family_1;
		display: flex;
		align-items: flex-end;
		position: fixed;
		z-index: 999;
		bottom: 0;
	}
	}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  {
	 /* tablet, landscape iPad, lo-res laptops ands desktops */ 
	}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
.joinChatContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	width: 225px;
	padding: 5px;
	h3 {
		font-size: 1.5rem;
		padding-top: 1rem;
		text-align: center;
	}
	span {
		font-size: 12px;
	}
	input {
		width: 175px;
		height: 40px;
		margin: 7px;
		border: 2px solid #43a047;
		border-radius: 5px;
		padding: 5px;
		font-size: 16px;
	}
	button {
		width: 175px;
		height: 40px;
		margin: 7px;
		border: none;
		border-radius: 5px;
		padding: 5px;
		font-size: 16px;
		background: #43a047;
		color: $color_2;
		cursor: pointer;
		&:hover {
			background: #2e7d32;
		}
	}
}
.chat-window {
	width: 300px;
	height: 420px;
	p {
		margin: 0;
	}
	.chat-header {
		height: 45px;
		border-radius: 6px;
		background: #263238;
		position: relative;
		cursor: pointer;
		p {
			display: block;
			padding: 0 1em 0 2em;
			color: $color_2;
			font-weight: 700;
			line-height: 45px;
		}
	}
	.chat-body {
		height: calc(450px - (45px + 70px));
		border: 1px solid #263238;
		background: #fff;
		position: relative;
		.message-container {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			overflow-x: hidden;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.message {
			height: auto;
			padding: 10px;
			display: flex;
			.message-content {
				width: auto;
				height: auto;
				min-height: 40px;
				max-width: 120px;
				background-color: $background-color_1;
				border-radius: 5px;
				color: $color_3;
				display: flex;
				align-items: center;
				margin-right: 5px;
				margin-left: 5px;
				padding-right: 5px;
				padding-left: 5px;
				overflow-wrap: break-word;
				word-break: break-word;
			}
			.message-meta {
				display: flex;
				font-size: 12px;
			}
		}
	}
	.chat-footer {
		height: 40px;
		border: 1px solid #263238;
		border-top: none;
		display: flex;
		input {
			height: 100%;
			flex: 85%;
			border: 0;
			padding: 0 0.7em;
			font-size: 1em;
			border-right: 1px dotted #607d8b;
			outline: none;
			font-family: $font-family_1;
		}
		button {
			border: 0;
			display: grid;
			place-items: center;
			cursor: pointer;
			flex: 15%;
			height: 100%;
			background: transparent;
			outline: none;
			font-size: 25px;
			color: $color_4;
			&:hover {
				color: $color_5;
			}
		}
	}
}
#you {
	justify-content: flex-start;
	.message-content {
		justify-content: flex-start;
	}
	.message-meta {
		justify-content: flex-start;
		margin-left: 5px;
	}
}
#other {
	justify-content: flex-end;
	.message-content {
		justify-content: flex-end;
		background-color: $background-color_2;
	}
	.message-meta {
		justify-content: flex-end;
		margin-right: 5px;
	}
}
.message-meta {
	#author {
		margin-left: 10px;
		font-weight: bold;
	}
}
.hide {
	opacity: 0 !important;
}