:root {
  --navBg: #f5f5f5;
  margin-top: 2px;
}

.navbar {
  background-color: var(--navBg);
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  /* border-bottom: 2px solid black; */
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: var(--navBg);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #070707;
  font-size: 18px;
  width: 95%;
  /* height: 30px; */
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 10px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #d8d8d8ee;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}
.nav-text-admin a {
  text-decoration: none;
  color: #530000;
  font-size: 18px;
  width: 95%;
  /* height: 30px; */
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 10px;
  border-radius: 4px;
}
.nav-text-admin-menu a {
  text-decoration: underline;
  color: #070707;
  font-size: 20px;
  width: 95%;
  /* height: 30px; */
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 10px;
  border-radius: 4px;
}
.navbar-toggle {
  background-color: var(--navBg);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

nav span {
  margin-left: 16px;
}