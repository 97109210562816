body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  /* text-align: center; */
.products {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
}